<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card
      v-if="selectedPlan"
      class="el-main cg-relative"
      :body-style="{ paddingBottom: '60px' }"
    >
      <el-row>
        <el-col :span="14">
          <el-form label-width="140px">
            <el-descriptions title="定价信息"></el-descriptions>
            <el-form-item label="计费模式">
              <el-radio-group v-model="selectedType">
                <el-radio v-for="item in typeList" :key="item.value" :label="item.value">
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="!isPower">
              <el-form-item label="起步费用">
                {{ selectedPlan.baseAmount }}元/{{ selectedPlan.baseMinutes }}分钟
              </el-form-item>
              <el-form-item label="加时费用">
                {{ selectedPlan.overtimeAmount }}元/{{ selectedPlan.overtimeMinutes }}分钟
              </el-form-item>
            </template>
            <power-table v-else class="cg-ml-72" :data-source="selectedPlan.powerChargeList" />
            <el-form-item label="免费时长">
              {{ selectedPlan.freeChargeDuration }}分钟
              <el-tooltip placement="top" content="订单结束时不满该时间，则不计算该订单费用">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="单日免费次数">
              {{ selectedPlan.freeChargeCount }}次
              <el-tooltip placement="top" content="每个用户每天可参与免费时长的次数">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="最大时长">
              {{ selectedPlan.maxChargeDuration }}分钟
              <el-tooltip placement="top" content="可以选择的最大充电时间">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="定价描述">
              {{ selectedPlan.chargePlanDescription || "--" }}
            </el-form-item>
            <el-descriptions title="下单设置"></el-descriptions>
            <el-form-item label="是否充满自停">
              {{ selectedPlan.fullStopLabel }}
            </el-form-item>
            <el-form-item label="候选顺序">
              {{ selectedPlan.candidatesOrderLabel }}
            </el-form-item>
            <el-form-item label="候选方式">
              {{ selectedPlan.candidatesTypeLabel }}
            </el-form-item>
          </el-form>
        </el-col>
        <el-col>
          <price-preview :pricing-option-info="pricingOptionInfo" />
        </el-col>
      </el-row>

      <div class="cg-absolute cg-bottom-0 cg-bg-white cg-py-12 cg-px-16">
        <el-button @click="onClickBack">返回</el-button>
        <el-button type="primary" @click="onClickUse">使用</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { chargePlanTransform, COMPANY_CHARGE_PLAN_CONST } from "@charge/common";
import { omit } from "lodash-es";

import PowerTable from "@/components-new/FeeRuleDetail/PowerTable.vue";
import { companyChargePlanApi } from "@/services/apis/company/charge-plan";
import { eventBus, eventBusKeys } from "@/utils/event-bus";

import PricePreview from "../components/PricePreview.vue";

export default {
  components: {
    PowerTable,
    PricePreview,
  },

  data() {
    return {
      suggestPlan: {},
      suggestPlanView: {},
      selectedType: COMPANY_CHARGE_PLAN_CONST.chargeModel.baseTime,
      typeList: COMPANY_CHARGE_PLAN_CONST.chargeModelList,
      pricingOptionInfo: {},
      id: "",
    };
  },

  computed: {
    selectedPlan() {
      return this.suggestPlanView[this.selectedType] || {};
    },
    isPower() {
      return this.selectedPlan.isPower;
    },
    isTime() {
      return this.selectedPlan.isTime;
    },
  },

  watch: {
    selectedType() {
      this.postPreviewData();
    },
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getSuggestList();
  },

  methods: {
    async getSuggestList() {
      this.suggestPlan = await companyChargePlanApi.defaultChargePlan();
      this.suggestPlanView = chargePlanTransform.convertDefaultChargeValue(this.suggestPlan);
      this.postPreviewData();
    },

    transformActionData() {
      const result = omit(this.suggestPlan[this.selectedType], ["id", "powerChargeList"]);
      result.powerChargeParamList = this.suggestPlan[this.selectedType].powerChargeList;
      return result;
    },

    async postPreviewData() {
      this.pricingOptionInfo = await companyChargePlanApi.preview(this.transformActionData());
    },

    onClickBack() {
      if (this.id) {
        this.$router.push(`/price/price-edit?id=${this.id}`);
      } else {
        this.$router.push("/price/price-add");
      }
    },

    onClickUse() {
      if (this.id) {
        eventBus.emit(eventBusKeys.editUseSuggestPrice, this.selectedType);
        this.$router.push(`/price/price-edit?id=${this.id}`);
      } else {
        eventBus.emit(eventBusKeys.addUseSuggestPrice, this.selectedType);
        this.$router.push("/price/price-add");
      }
    },
  },
};
</script>
