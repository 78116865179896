<template>
  <div class="_container">
    <el-table ref="table" :data="dataSource" :row-class-name="rowClassCallback">
      <el-table-column label="序号" align="center" header-align="center">
        <template slot-scope="{ $index }">{{ $index + 1 }}</template>
      </el-table-column>
      <el-table-column label="功率范围（瓦）" align="center">
        <template slot-scope="{ row }">{{ row.minPower }}-{{ row.maxPower }}</template>
      </el-table-column>
      <el-table-column label="起步费用" align="center">
        <template slot-scope="{ row }">{{ row.baseAmount }}元/{{ row.baseMinutes }}分钟</template>
      </el-table-column>
      <el-table-column label="加时费用" align="center">
        <template slot-scope="{ row }">
          {{ row.overtimeAmount }}元/{{ row.overtimeMinutes }}分钟
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "PowerTable",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    maxPower: {
      type: Number,
      default: undefined,
    },
  },

  methods: {
    rowClassCallback({ row, rowIndex }) {
      if (this.maxPower !== undefined) {
        if (rowIndex === 0) {
          return this.maxPower < row.maxPower ? "_fee-rule-tr" : "";
        } else if (rowIndex < this.dataSource.length - 1) {
          return this.maxPower > row.minPower && this.maxpower < row.maxPower ? "_fee-rule-tr" : "";
        } else {
          return this.maxPower > row.minPower ? "_fee-rule-tr" : "";
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
._container ::v-deep {
  ._fee-rule-tr {
    background: #d1fcff;
    &:hover {
      background: #d1fcff !important;
      td {
        background: unset !important;
      }
    }
  }
}
</style>
